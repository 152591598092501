import React from 'react';
import Performance from './components/Performace';
import Header from './components/Header';
export default function PerformacePage(){
  return (
	<>
	<div className='container'>
		<Header/>
	  <Performance/>
	  </div>
	</>
  );
};
