import React from 'react';
import Header from './components/Header';
import Services from './components/Services';
export default function ServicesPage(){
  return (
	<>
	<div className='container'>
	  <Header/>
	  <Services/>
	  </div>
	</>
  );
};
