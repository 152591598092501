import React from 'react';
import Header from './components/Header';
import RegisterCard from './components/RegisterCard';
export default function RegisterPage(){
  return (
	<>
	<div className='container'>
	  <Header/>
	  <RegisterCard/>
	  </div>
	</>
  );
};
