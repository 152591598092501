import React from 'react';
import Header from './components/Header';
import LoginCard from './components/LoginCard';
export default function LoginPage(){
  return (
	<>
	<div className='container'>
	  <Header/>
	  <LoginCard/>
	  </div>
	</>
  );
};
