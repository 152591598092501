import React from 'react';
import Header from './components/Header';
import About from './components/About';
export default function AboutPage(){
  return (
	<>
	<div className='container'>
	  <Header/>
	  <About/>
	  </div>
	</>
  );
};
